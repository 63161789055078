import React, { FC } from 'react';
import { graphql } from 'gatsby';
import BuyNowCards from 'containers/BuyNowCards';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import { BANNER } from 'components/Banner/models.d';
import { SIZECARDNODES } from 'components/ShopCard/models.d';

import './WhereToBuyPage.scss';

import 'swiper/css';

const WhereToBuyPage: FC<{ data: WhereToBuyPageTypes.WhereToBuyPageSiteTypes }> = ({
  data: {
    whereToBuy: { urls, seo, shopList, introductionText, banner, pageName },
    homepage: { carouselButtons },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner banner={banner} variant={BANNER.VARIANT.PRIMARY} />

      <Container fluid className="where-to-buy">
        <Container noPadding className="where-to-buy__container">
          <Typography
            variant="paragraph3"
            weight="regular"
            customClass="where-to-buy__introduction"
          >
            {introductionText}
          </Typography>
          <BuyNowCards
            shopList={shopList}
            size={SIZECARDNODES.NORMAL}
            carouselButtons={carouselButtons}
          />
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query WhereToBuyPageQuery($lang: String) {
    whereToBuy(lang: { eq: $lang }) {
      ...WhereToBuyPageFragment
    }
    homepage {
      carouselButtons {
        nextBtnAria
        prevBtnAria
      }
    }
  }
`;

export default WhereToBuyPage;
